import React from 'react';
import Helmet from 'react-helmet';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { RegistryBrowser } from 'components/registry';
import { Layout } from 'layouts';

const Wrapper = styled.div`
  h1 {
    color: ${props => props.theme.colors.black.base};
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
`;

const Browse = ({ location }) => {
  const breakpoints = useBreakpoint();

  return (
    <Layout location={location} background={breakpoints.m ? '' : 'teal'}>
      <Helmet title={'Browse AWS CloudFormation templates'} />
      <SEO
        description='Browse AWS CloudFormation templates'
        banner='https://media.graphcms.com/output=format:jpg/nbVDfaJbSuejiDFgRWEQ'
      />
      <Wrapper>
        <RegistryBrowser />
      </Wrapper>
    </Layout>
  );
};

export default Browse;

Browse.propTypes = {
  location: PropTypes.object
};
